<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo" href="/">
        <!-- <vuexy-logo /> -->
        <b-img
        :src="require('@/assets/images/avatars/13-small.png')"
          style="width: 30px; height: 30px"

          alt="logo"
          class=""
        ></b-img>
        <br/>
        <h2 class="brand-text text-primary ml-1" style="padding-bottom:10px">Optimal</h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="imgUrl" alt="Register V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <!-- <b-link href="/">
            <b-img
              :src="require('@/assets/images/avatars/optimalfull.png')"
              style="width: 200px"
              class="mb-1"
            />
          </b-link> -->
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            Buat Akun Baru 🚀
          </b-card-title>
          <b-card-text class="mb-2">
            Registrasi yang cepat dan mudah
          </b-card-text>

          <b-form
            method="post"
            enctype="multipart/form-data"
            class="auth-register-form mt-2"
            @submit="addUser"
          >
            <!-- form -->
            <validation-observer ref="registerForm">
              <b-form-group label="Email" label-for="login-email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="email"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    placeholder="Masukkan Email"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="Masukkan Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group label="Nama Lengkap" label-for="register-nama">
                <validation-provider
                  #default="{ errors }"
                  name="Nama"
                  rules="required"
                >
                  <b-form-input
                    id="register-nama"
                    v-model="nama"
                    name="register-nama"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Masukan Nama Lengkap"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group label="Kampus" label-for="register-kampus">
                <validation-provider
                  #default="{ errors }"
                  name="Kampus"
                  rules="required"
                >
                  <!-- <b-form-input
                    id="register-kampuis"
                    v-model="kampus"
                    name="register-kampus"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Pilih Kampus"
                  /> -->

                  <v-select
                  name="register-kampus"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Pilih Kampus"
                    v-model="kampus"
                    :value="kampus"
                    :reduce="(nama) => nama.nama"
                    :options="listKampus"
                    label="nama"
                    >
                    </v-select>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group label="Jurusan" label-for="register-jurusan">
                <validation-provider
                  #default="{ errors }"
                  name="Jurusan"
                  rules="required"
                >
                  <b-form-select
                    id="register-jurusan"
                    v-model="jurusan"
                    name="register-jurusan"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Jurusan"
                    :options="option_jurusan"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group label="Strata/Jenjang" label-for="register-jenjang">
                <validation-provider
                  #default="{ errors }"
                  name="Jenjang"
                  rules="required"
                >
                  <b-form-select
                    id="register-jenjang"
                    v-model="jenjang"
                    name="register-jenjang"
                    :options="option_jenjang"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Kebidanan atau Keperawatan"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group label="No Telp." label-for="register-telepon">
                <validation-provider
                  #default="{ errors }"
                  name="Telepon"
                  rules="required"
                >
                  <b-form-input
                    id="register-telepon"
                    v-model="telepon"
                    name="register-telepon"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Masukan No Telepon/ HP"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </validation-observer>
            <b-button
              variant="primary"
              type="submit"
              style="width: 100%"
              @click.prevent="addUser"
            >
              <feather-icon icon="SaveIcon" style="color: white" />
              <span class="align-middle"
                ><b style="color: white"> Daftar</b></span
              >
            </b-button>
          </b-form>
          <p class="text-center mt-2">
            <span>Sudah Memiliki Akun?</span>
            <b-link :to="{ name: 'login' }">
              <span>&nbsp;Untuk Login</span>
            </b-link>
          </p>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<!-- <b-form-group>
                <b-form-checkbox
                  id="register-privacy-policy"
                  v-model="status"
                  name="checkbox-1"
                >
                  Saya Setuju
                  <b-link>Syarat dan Ketentuan</b-link>
                </b-form-checkbox>
              </b-form-group> -->

<!-- <b-button
                variant="primary"
                block
                type="submit"
                @click.prevent="validationForm"
              >
                Daftar
              </b-button> -->

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormSelect,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardTitle,
  BCardText,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Swal from "sweetalert2";
export default {
  components: {
    VuexyLogo,
    BRow,
    Swal,
    vSelect,
    BImg,
    BFormSelect,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      email: "",
      password: "",
      nama: "",
      kampus: "",
      jurusan: "",
      jenjang: "",
      telepon: "",
      sideImg: require("@/assets/images/pages/register-v2.svg"),
      option_jenjang: [
        { text: "Profesi", value: "Profesi" },
        { text: "Diploma 1", value: "D1" },
        { text: "Diploma 2", value: "D2" },
        { text: "Diploma 3", value: "D3" },
        { text: "Diploma 4", value: "D4" },
        { text: "Sarjana - S1", value: "S1" },
        { text: "Sarjana - S2", value: "S2" },
        { text: "Sarjana - S3", value: "S3" },
      ],
      option_jurusan: [
        { value: "Kebidanan", text: "Kebidanan" },
        { value: "Keperawatan", text: "Keperawatan" },
        { value: "Farmasi", text: "Farmasi" },
        { value: "Teknik Gigi", text: "Teknik Gigi" },
        { value: "Teknik Elektromedik", text: "Teknik Elektromedik" },
        { value: "Gizi", text: "Gizi" },
        {
          value: "Teknologi Laboratorium Medis",
          text: "Teknologi Laboratorium Medis",
        },
        { value: "Radiologi", text: "Radiologi" },
        { value: "Keperawatan Anestiologi", text: "Keperawatan Anestiologi" },
        { value: "Terapi Gigi", text: "Terapi Gigi" },
        {
          value: "Rekam Medis dan Informasi Kesehatan",
          text: "Rekam Medis dan Informasi Kesehatan",
        },
        { value: "Terapi Okupasi", text: "Terapi Okupasi" },
        { value: "Terapi Wicara", text: "Terapi Wicara" },
        { value: "Sanitasi Lingkungan", text: "Sanitasi Lingkungan" },
        { value: "Kesehatan Gigi", text: "Kesehatan Gigi" },
        { value: "Optometri", text: "Optometri" },
        { value: "Akupunktur", text: "Akupunktur" },
        { value: "Ortotikprotestik", text: "Ortotikprotestik" },
        { value: "Fisioterapi", text: "Fisioterapi" },
      ],
      // validation
      required,
      listKampus:[]
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/register-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  mounted() {
    this.getKampus();
  },
  methods: {
    // validationForm() {
    //   this.$refs.registerForm.validate().then((success) => {
    //     if (success) {
    //       this.$toast({
    //         component: ToastificationContent,
    //         props: {
    //           title: "Form Submitted",
    //           icon: "EditIcon",
    //           variant: "success",
    //         },
    //       });
    //     }
    //   });
    // },
    addUser(e) {
      e.preventDefault();
      this.$refs.registerForm.validate().then((success) => {
        if (success) {
          const formData = new FormData();
          formData.append("email", this.email);
          formData.append("password", this.password);
          formData.append("nama", this.nama);
          formData.append("kampus", this.kampus);
          formData.append("jurusan", this.jurusan);
          formData.append("jenjang", this.jenjang);
          formData.append("telepon", this.telepon);
          // console.log("asd", this.email);
          this.$http
            .post(process.env.VUE_APP_BACKEND_URL + "users/create", formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((res) => {
              console.log("asd", res.data.status);
              if (res.data.status == 400) {
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: "Notification",
                      icon: "XCircleIcon",
                      text: "Email Sudah Ada",
                      variant: "danger",
                    },
                  },
                  {
                    timeout: 5000,
                  },
                  {
                    position: "top-right",
                  }
                );
              } else {
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: "Notification",
                      icon: "CheckCircleIcon",
                      text: "Berhasil Register Data",
                      variant: "success",
                    },
                  },
                  {
                    timeout: 5000,
                  },
                  {
                    position: "top-right",
                  }
                );
                this.email = "";
                this.password = "";
                this.nama = "";
                this.kampus = "";
                this.jurusan = "";
                this.jenjang = "";
                this.telepon = "";

                this.$router.push({ name: "login" });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },
    getKampus() {
      this.$http
        .get(process.env.VUE_APP_BACKEND_URL + "collages/view")
        .then((res) => {
          this.listKampus = res.data.data;
        });
    },
  },
};
/* eslint-disable global-require */
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
